import { Component, For, Show } from "solid-js";
import { useSiteContext } from "~/utils/contexts";
import { FooterFields } from "./Footer.block";
import { A } from "@solidjs/router";
import { Img } from "~/components/image";
// import MeasurementUnitSelection from "./MeasurementUnitSelection";
// import CountrySelection from "./CountrySelection";
// import { Icon } from "solid-heroicons";
// import { sun, moon, pencilSquare } from "solid-heroicons/outline";

interface FooterSectionProps {
  label?: string;
  links?: Array<{
    label?: string;
    url?: string;
    newTab?: boolean;
    icon?: string;
    highlight?: boolean;
  }>;
}

const coreValues = [
  "Deliver WOW Through Service",
  "Be Real and Live in Integrity",
  "Be Different, Have Fun and Deliver Happiness",
  "Build a Positive Team and Family Environment",
  "Be Passionate, Committed and Driven",
  "Inspire Innovation and Drive Change",
  "Be Humble",
  "Be Creative, Open Minded and Share Ideas",
  "Deliver Transparent, Open, Honest Communication",
  "Love Learning and Pursue Growth and Development",
];

const randomValue = Math.floor(Math.random() * coreValues.length);

const FooterSection: Component<FooterSectionProps> = (props) => (
  <div class="sm:col-span-2 px-2 py-3 sm:py-0 border-b last:border-b-0 sm:border-0">
    <input
      type="checkbox"
      name="submenu"
      id={props.label}
      class="accordion-input peer sr-only"
    />
    <label
      for={props.label}
      class={`
        accordion-label
        
        flex 
        justify-between 
        items-center
        
        cursor-pointer 
        sm:cursor-default
        
        peer-checked:-ml-2
        peer-checked:mb-2

        peer-checked:sm:ml-0
        peer-checked:sm:mb-0

        transition-[margin-bottom,_margin-left]
        duration-[750ms]
        `}
    >
      <div class=" text-lg font-bold block sm:mb-3">{props.label}</div>
    </label>
    <ul
      class="
        flex 
        flex-col 
        text-base
        font-medium
        
        max-h-0 
        peer-checked:max-h-80
        sm:max-h-max 
        overflow-hidden 

        transition-[max-height]
        duration-700"
    >
      <For each={props.links}>
        {(link) => (
          <li>
            <A
              class="hover:text-roma-blue transition-colors duration-200 py-2 flex items-center"
              href={link.url as string}
              target={link.newTab ? "_blank" : ""}
              title={`Link to ${link.label}`}
            >
              <span>{link.label}</span>
              <Show when={link.icon}>
                <Img
                  src={link.icon as string}
                  width={16}
                  height={16}
                  alt=""
                  class="w-4 ml-3"
                />
              </Show>
            </A>
          </li>
        )}
      </For>
    </ul>
  </div>
);

const Footer: Component<FooterFields & { jobs: number }> = (props) => {
  const { isDarkMode, setIsDarkMode, VERSION } = useSiteContext();
  return (
    <>
      <footer id="footer-navigation" class="z-10 relative pb-8 print:hidden">
        <div class="bg-roma-grey py-5">
          <div class="container mx-auto py-5 sm:py-10 ">
            <div class="grid sm:grid-cols-12 sm:gap-3 md:pr-2">
              <div class="sm:col-span-4 mb-4 pr-2">
                <div class="font-bold text-base mb-1">
                  Core Value #{randomValue + 1}
                </div>
                <div class="font-bold text-2xl leading-6">
                  {coreValues[randomValue]}
                </div>
                <A
                  href="/about/our-philosophy#core-values"
                  class="block mt-2 text-base text-roma-blue"
                >
                  More Values
                </A>
              </div>
              <For each={props.navigation}>
                {(section) => <FooterSection {...section} />}
              </For>
            </div>
            <div class="flex max-sm:justify-evenly space-x-4 px-2 -ml-3 mt-4 sm:mt-0">
              <A
                class=""
                target="_blank"
                rel="noopener"
                href="https://www.instagram.com/romamoulding"
                title="Instagram"
              >
                <div class="w-9 h-9 flex items-center justify-center border-2 border-roma-dark-grey rounded-full text-roma-dark-grey hover:text-black transition-colors">
                  <svg width="1.5em" height="1.5em" viewBox="0 0 1024 1024">
                    <path
                      fill="currentColor"
                      d="M512 378.7c-73.4 0-133.3 59.9-133.3 133.3S438.6 645.3 512 645.3S645.3 585.4 645.3 512S585.4 378.7 512 378.7zM911.8 512c0-55.2.5-109.9-2.6-165c-3.1-64-17.7-120.8-64.5-167.6c-46.9-46.9-103.6-61.4-167.6-64.5c-55.2-3.1-109.9-2.6-165-2.6c-55.2 0-109.9-.5-165 2.6c-64 3.1-120.8 17.7-167.6 64.5C132.6 226.3 118.1 283 115 347c-3.1 55.2-2.6 109.9-2.6 165s-.5 109.9 2.6 165c3.1 64 17.7 120.8 64.5 167.6c46.9 46.9 103.6 61.4 167.6 64.5c55.2 3.1 109.9 2.6 165 2.6c55.2 0 109.9.5 165-2.6c64-3.1 120.8-17.7 167.6-64.5c46.9-46.9 61.4-103.6 64.5-167.6c3.2-55.1 2.6-109.8 2.6-165zM512 717.1c-113.5 0-205.1-91.6-205.1-205.1S398.5 306.9 512 306.9S717.1 398.5 717.1 512S625.5 717.1 512 717.1zm213.5-370.7c-26.5 0-47.9-21.4-47.9-47.9s21.4-47.9 47.9-47.9s47.9 21.4 47.9 47.9a47.84 47.84 0 0 1-47.9 47.9z"
                    />
                  </svg>
                </div>
              </A>
              <A
                class=""
                target="_blank"
                rel="noopener"
                href="https://www.facebook.com/romamoulding"
                title="Facebook"
              >
                <div class="w-9 h-9 flex items-center justify-center border-2 border-roma-dark-grey rounded-full text-roma-dark-grey hover:text-black transition-colors">
                  <svg width="1.25rem" height="1.25rem" viewBox="0 0 896 1664">
                    <path
                      fill="currentColor"
                      d="M895 12v264H738q-86 0-116 36t-30 108v189h293l-39 296H592v759H286V905H31V609h255V391q0-186 104-288.5T667 0q147 0 228 12z"
                    />
                  </svg>
                </div>
              </A>
              <A
                class=""
                target="_blank"
                rel="noopener"
                href="https://www.tiktok.com/@romamoulding"
                title="TikTok"
              >
                <div class="w-9 h-9 flex items-center justify-center border-2 border-roma-dark-grey rounded-full text-roma-dark-grey hover:text-black transition-colors">
                  <svg width="1.25rem" height="1.25rem" viewBox="0 0 448 512">
                    <path
                      fill="currentColor"
                      d="M448 209.91a210.06 210.06 0 0 1-122.77-39.25v178.72A162.55 162.55 0 1 1 185 188.31v89.89a74.62 74.62 0 1 0 52.23 71.18V0h88a121.18 121.18 0 0 0 1.86 22.17A122.18 122.18 0 0 0 381 102.39a121.43 121.43 0 0 0 67 20.14Z"
                    />
                  </svg>
                </div>
              </A>
              <A
                class=""
                target="_blank"
                rel="noopener"
                href="https://www.youtube.com/user/RomaMoulding"
                title="YouTube"
              >
                <div class="w-9 h-9 flex items-center justify-center border-2 border-roma-dark-grey rounded-full text-roma-dark-grey hover:text-black transition-colors">
                  <svg width="1.25em" height="1.25rem" viewBox="0 0 1792 1280">
                    <path
                      fill="currentColor"
                      d="m711 872l484-250l-484-253v503zM896 10q168 0 324.5 4.5T1450 24l73 4q1 0 17 1.5t23 3t23.5 4.5t28.5 8t28 13t31 19.5t29 26.5q6 6 15.5 18.5t29 58.5t26.5 101q8 64 12.5 136.5T1792 532v176q1 145-18 290q-7 55-25 99.5t-32 61.5l-14 17q-14 15-29 26.5t-31 19t-28 12.5t-28.5 8t-24 4.5t-23 3t-16.5 1.5q-251 19-627 19q-207-2-359.5-6.5T336 1256l-49-4l-36-4q-36-5-54.5-10t-51-21t-56.5-41q-6-6-15.5-18.5t-29-58.5T18 998q-8-64-12.5-136.5T0 748V572q-1-145 18-290q7-55 25-99.5T75 121l14-17q14-15 29-26.5T149 58t28-13t28.5-8t23.5-4.5t23-3t17-1.5q251-18 627-18z"
                    />
                  </svg>
                </div>
              </A>
              <A
                class=""
                target="_blank"
                rel="noopener"
                href="https://www.linkedin.com/company/roma-moulding-inc/"
                title="LinkedIn"
              >
                <div class="w-9 h-9 flex items-center justify-center border-2 border-roma-dark-grey rounded-full text-roma-dark-grey hover:text-black transition-colors">
                  <svg width="1rem" height="1rem" viewBox="0 0 1536 1504">
                    <path
                      fill="currentColor"
                      d="M349 497v991H19V497h330zm21-306q1 73-50.5 122T184 362h-2q-82 0-132-49T0 191q0-74 51.5-122.5T186 20t133 48.5T370 191zm1166 729v568h-329V958q0-105-40.5-164.5T1040 734q-63 0-105.5 34.5T871 854q-11 30-11 81v553H531q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52t87-43.5T1157 474q171 0 275 113.5T1536 920z"
                    />
                  </svg>
                </div>
              </A>
            </div>
          </div>
        </div>
        <div class="container grid sm:grid-cols-7 mx-auto py-9">
          <A class="flex sm:col-span-2" href="/">
            <Img
              src="/img/logo-tm.svg"
              width={185}
              height={24}
              layout="constrained"
              alt="RomaMoulding.com"
            />
          </A>
          <div class="mt-4 sm:mt-0 sm:col-span-5 lg:col-span-4 flex flex-col sm:items-center sm:flex-row justify-center text-xs space-y-4 sm:space-y-0 sm:child:px-4 sm:divide-x">
            <A class="font-bold" href="/privacy-policy">
              Privacy Policy
            </A>
            <A class="font-bold text-roma-blue" href="/careers">
              Careers{" "}
              <Show when={props.jobs > 0}>
                <span>- We're hiring! ({props.jobs})</span>
              </Show>
            </A>
            <p>&copy;{new Date().getFullYear()} Roma Moulding</p>
            {/* <CountrySelection /> */}
            {/* <Show when={import.meta.env.VITE_STAGING == "true"}> */}
            {/* <MeasurementUnitSelection /> */}
            {/* <div>
                <button
                  onClick={() => {
                    setIsDarkMode(!isDarkMode());
                  }}
                >
                  <Icon path={isDarkMode() ? sun : moon} class="w-5" />
                </button>
              </div> */}
            {/* </Show> */}
          </div>
          <p class="text-gray-200 text-xs font-normal">Version {VERSION}</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
