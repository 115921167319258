import { createEffect, For } from "solid-js";
import {
  createAsync,
  useAction,
  useSubmission,
  useSubmissions,
} from "@solidjs/router";

import {
  getFavourites,
  useFavourites,
  deleteFavouriteAction,
} from "~/services/favourites";

export const FavouritesPanel = () => {
  // TODO get actual product data back from api based on favourites list..

  const favourites = createAsync(async () => {
    const favs = await getFavourites();

    if (favs) {
      return Array.from(favs).map(([key, value]) => {
        const [sku, type] = key.split("-");
        return { sku, type, value };
      });
    }
  });

  const deleteFav = useAction(deleteFavouriteAction);
  const deletingFav = useSubmission(deleteFavouriteAction);


  return (
    <div class="grow overflow-y-auto">
      <ul
        class="divide-y overflow-y-auto flex flex-col text-roma-dark-grey"
        classList={{ "pointer-events-none": deletingFav.pending }}
      >
        <For each={favourites()}>
          {(fav) => (
            <li
              class="py-2 relative"
              classList={{
                "text-gray-300": deletingFav.input?.includes(fav.value.ID),
              }}
            >
              <p>SKU: {fav.sku}</p>
              <p>TYPE: {fav.type}</p>
              <p>ID: {fav.value.ID}</p>
              <button
                class="absolute top-2 right-2 text-xs text-red-500"
                onClick={() => deleteFav(fav.value.ID)}
              >
                DELETE
              </button>
            </li>
          )}
        </For>
      </ul>
    </div>
  );
};
