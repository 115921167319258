import { type Component, For } from "solid-js";
// import { Motion } from "@motionone/solid";
import { Motion } from "solid-motionone";
// import Carousel from "~/components/content/Carousel";
import { Carousel } from "~/components/ui";
import { SearchProductCard } from "./SearchProductCard";

import { imageUrl } from "~/utils/products";

type ProductResultProps = {
  products: any;
  title?: string;
};

export const ProductResults: Component<ProductResultProps> = (props) => {
  return (
    <Motion.div
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{
        opacity: 0,
        x: 100,
        transition: {
          duration: 0.25,
          delay: 0,
        },
      }}
      transition={{ duration: 0.25, delay: 0 }}
      class="max-md:px-2 md:pl-8 py-3 md:py-6 "
    >
      <Carousel title={props.title} size="small">
        <For each={props.products.Results}>
          {(prod, index) => (
            <SearchProductCard
              class="snap-start pl-2 pr-2 first:ml-0 last:mr-0 shrink-0  w-1/2 xs:w-1/3 sm:w-1/4 md:w-1/4 lg:w-1/5"
              product={prod}
              image1={imageUrl(prod.SKU, prod.DisplayType, 1)}
              type={prod.DisplayType}
              index={index}
            />
          )}
        </For>
      </Carousel>
    </Motion.div>
  );
};
