import {
  Component,
  For,
  Setter,
  Show,
  createMemo,
  createEffect,
  createSignal,
  useContext,
  Index,
} from "solid-js";
import { SiteContext } from "~/utils/contexts";
import { debounce } from "@solid-primitives/scheduled";
// import { A } from "solid-start";
import { A } from "@solidjs/router";
// import { Motion, Presence } from "@motionone/solid";
import { Motion, Presence } from "solid-motionone";
import { Icon } from "solid-heroicons";
import { arrowRight, magnifyingGlass } from "solid-heroicons/outline";
import { EventType } from "@solid-primitives/analytics";

import { useSiteContext, useSessionContext } from "~/utils/contexts";

type Suggestion = {
  label: string;
  href?: string;
  type?: string;
};
type SuggestionList = Suggestion[];
type SuggestionGroup = { groupName: string; list: SuggestionList };

type SearchSuggestionsProps = {
  list: SuggestionGroup[];
  setSearchValue: Setter<string | undefined>;
};

export const SearchSuggestions: Component<SearchSuggestionsProps> = (props) => {
  const { addToRecentlySearched, clearRecentlySearched, session } =
    useSessionContext();
  const { track } = useSiteContext();

  const addToRecents = debounce(
    (val: string) => addToRecentlySearched(val),
    2000
  );
  const [hidden, setHidden] = createSignal(false);


  const testList = createMemo(() => {
    if (!session.recentlySearched) return props.list;
    const copy = [...props.list];
    const recents = session.recentlySearched;
    const parsed = JSON.parse(recents);
    const formattedRecents = parsed.map((item: string) => ({
      label: item,
      type: "search",
    }));
    copy[0] = { groupName: "Recent Searches", list: formattedRecents };

    return copy;
  });

  // TODO: max-h on container..can only animate fixed values, figure out better
  // value once we know what the max # of suggestions will be.

  return (
    <div class={`relative`}>
      <button
        class="flex md:hidden absolute right-8 group-data-[no-results=true]:left-0 top-1 z-10 text-xs  text-roma-medium-grey justify-center items-center  px-1 py-0.5 "
        onClick={() => setHidden(!hidden())}
      >
        <span
          class={`text-xs mb-0.5 ${
            hidden() ? "rotate-45" : ""
          } transition-transform transform duration-250`}
        >
          &#10005;
        </span>
        <span class="ml-1">{hidden() ? "Show" : "Hide"} Suggestions</span>
      </button>
      <div
        class={`${
          hidden()
            ? "max-h-6 max-md:overflow-y-hidden "
            : "max-h-[500px] overflow-auto"
        } flex flex-wrap gap-x-10 py-2 sm:py-4 items-start transition-all w-full px-2 md:px-4 `}
      >
        <For each={testList()}>
          {(group) => (
            <ul class={` py-4 ${hidden() ? "max-md:pt-4" : ""} `}>
              <li>
                <h2 class="text-roma-medium-grey text-xs sm:text-sm">
                  {group.groupName}
                </h2>
                <ul class="py-2">
                  <For each={group.list}>
                    {(sugg, i) => (
                      <Presence exitBeforeEnter>
                        <Motion.li
                          initial={
                            i() === 0 ? { opacity: 0, y: -10 } : undefined
                          }
                          animate={i() === 0 ? { opacity: 1, y: 0 } : undefined}
                          exit={i() === 0 ? { opacity: 1, y: 100 } : undefined}
                          transition={
                            i() === 0
                              ? { duration: 0.55, delay: 0.075 }
                              : undefined
                          }
                          class="hover:text-roma-blue text-sm sm:text-base"
                        >
                          <Show
                            when={sugg.type === "search"}
                            fallback={
                              <A
                                class="flex items-center "
                                href={sugg.href!}
                                onClick={() => {
                                  track(EventType.Event, {
                                    category: "search",
                                    action: "suggestion_selected",
                                    value: sugg.label,
                                  });
                                }}
                              >
                                <Icon
                                  path={arrowRight}
                                  stroke-width={2.5}
                                  class="mr-2 inline-block w-3 text-gray-400 shrink-0"
                                />
                                <span>{sugg.label}</span>
                              </A>
                            }
                          >
                            <button
                              class="flex items-center text-left "
                              onClick={() => {
                                track(EventType.Event, {
                                  category: "search",
                                  action: "search_suggestion_selected",
                                  value: sugg.label,
                                });
                                props.setSearchValue(sugg.label);
                                addToRecents(sugg.label);
                              }}
                            >
                              <Icon
                                path={magnifyingGlass}
                                stroke-width={2.5}
                                class="mr-2 inline-block w-3 text-gray-400 shrink-0"
                              />
                              <span>{sugg.label}</span>
                            </button>
                          </Show>
                        </Motion.li>
                      </Presence>
                    )}
                  </For>
                  <Show when={group.groupName === "Recent Searches"}>
                    <li>
                      <button
                        class="text-roma-blue text-xs"
                        onClick={clearRecentlySearched}
                      >
                        Clear
                      </button>
                    </li>
                  </Show>
                </ul>
              </li>
            </ul>
          )}
        </For>
      </div>
    </div>
  );
};
