import { Component, For } from "solid-js";
// import { A } from "solid-start";
import { A } from "@solidjs/router";
// import { Motion } from "@motionone/solid";
import { Motion } from "solid-motionone";
// import Carousel from "~/components/content/Carousel";
import { Carousel } from "~/components/ui";
// import { ResultGroup } from "../searchLogic";
import { ResultGroup } from "~/utils/searchLogic";

type PageResultProps = {
  pages: ResultGroup
  title: string;
};

export const PageResults: Component<PageResultProps> = (props) => {
  return (
    <Motion.div
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{
        opacity: 0,
        x: 100,
        transition: {
          duration: 0.25,
          delay: 0,
        },
      }}
      transition={{ duration: 0.25, delay: 0.25 }}
      class=" max-md:px-2 md:pl-8 py-3 md:py-6"
    >
      <Carousel title={props.title} size="small">
        <For each={props.pages.Results}>
          {(item) => (
            <A href={item.url} class="snap-start pl-2 pr-2 first:ml-0 last:mr-0 shrink-0 w-1/3 sm:w-1/3 md:w-1/4 lg:w-1/5 group hover:text-black">
              <div class="aspect-square border bg-roma-grey group-hover:border-gray-300 flex flex-col justify-between py-2 px-3">
                <h3 class="text-roma-dark-grey group-hover:text-black text-base line-clamp-2">{item.title}</h3>
                <p class="text-roma-medium-grey text-sm line-clamp-2">
                  {item.description}
                </p>
              </div>
            </A>
          )}
        </For>
      </Carousel>
    </Motion.div>
  );
};
