import {
  For,
  Show,
  createEffect,
  createSignal,
  type Component,
  createMemo,
  Suspense,
} from "solid-js";
import { useSessionContext } from "~/utils/contexts";
import { _retrieveCart } from "~/services/roma-api/cart";
import {
  AccessorWithLatest,
  useAction,
  useSubmission,
  createAsync,
  revalidate,
  useSubmissions,
} from "@solidjs/router";
import { type ListCartResponse } from "~/services/roma-api/cart";
import { SelectBox, TextFieldInput } from "~/components/inputs";
import {
  updateCurrentCartAction,
  createCartAction,
  retrieveCart,
  useCart,
  addToCartAction,
  deleteCartAction,
  clearCartAction,
} from "~/services/cart";
import { CartSummary, CartDetail } from "~/services/roma-api/cart/types";
import { listCarts } from "~/services/cart";
import { BaseLoader } from "~/components/utility";
import { Motion, Presence } from "solid-motionone";
import Button from "~/components/Button";
import { numberWithCommas } from "~/utils/helpers";
import { usePrompt } from "~/components/utility";

type CartPanelProps = {
  carts: AccessorWithLatest<ListCartResponse | undefined>;
  currentCart: AccessorWithLatest<CartDetail | undefined>; // call here or in SidePanel or both?
};

export const CartPanel: Component<CartPanelProps> = (props) => {
  // TODO - should I be using session directly here with createASync/deferStream?
  const { session, isPartner } = useSessionContext();
  const [showCartOptions, setShowCartOptions] = createSignal<
    "switch" | "add" | undefined
  >();
  // const sessionAsync = createAsync(() => getSession(), { deferStream: true });
  // ? wtf doesn't a cart_id change trigger suspense for below?
  const cart = createAsync(() => retrieveCart(session()?.cart_id));
  // ? below will work if providing arg as an accessor, needs some Type cleanup though if thats
  // ? the route to take..createAsync above works just fine though
  // const cart = useCart(()=>session()?.cart_id);
  const [Prompt, openPrompt] = usePrompt();

  const updateCart = useAction(updateCurrentCartAction);
  const updatingCart = useSubmission(updateCurrentCartAction);

  const createCart = useAction(createCartAction);
  const creatingCart = useSubmission(createCartAction);

  const addToCart = useAction(addToCartAction);
  const addingToCart = useSubmission(addToCartAction);

  const deleteCart = useAction(deleteCartAction);
  const deletingCart = useSubmission(deleteCartAction);

  const clearCart = useAction(clearCartAction);
  const clearingCart = useSubmission(clearCartAction);

  // TODO delete cart button, checkout button, keep ordering button

  // !TESTING
  const aCartActionIsPending = () =>
    updatingCart.pending ||
    creatingCart.pending ||
    addingToCart.pending ||
    deletingCart.pending ||
    clearingCart.pending;

  const handleDelete = async () => {
    const confirmed = await openPrompt({
      title: "Confirm Cart Deletion",
      description:
        "Are you sure you want to delete this cart? This action is irreversible.",
      requireInteraction: true,
      options: [
        { label: "Cancel - Return to Cart", value: false, priority: true },
        { label: "Delete Cart", value: true, style: "red" },
      ],
    });

    if (confirmed) {
      await deleteCart();
    }
    return;
  };

  const handleEmpty = async () => {
    const confirmed = await openPrompt({
      title: "Empty Current Cart",
      description:
        "Are you sure you want to remove all items from this cart? This action is irreversible.",
      requireInteraction: true,
      options: [
        { label: "Cancel - Return to Cart", value: false, priority: true },
        { label: "Empty Cart", value: true, style: "red" },
      ],
    });

    if (confirmed) {
      await clearCart();
    }
    return;
  };

  const cartOptions = createMemo(() => {
    if (!props.carts()?.Results) return;
    return props.carts()?.Results.map((cart) => ({
      label: `${cart.Name} [${cart.LineCount}] (${cart.ID})`,
      value: cart.ID,
    }));
  });

  // !DELETE
  const deleteAllCartsButTheTwoIWant = async (idToStartWith: string) => {
    let currentId = idToStartWith;
    const ID_TO_STOP_AT = "090c2c2a-348e-402e-b974-35eebb62d7d0";

    while (true) {
      try {
        const response = await fetch(
          `${import.meta.env.VITE_ROMA_API}/cart/${currentId}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${session()?.token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.CurrentCartID === ID_TO_STOP_AT) {
          console.log(`Desired CartID ${ID_TO_STOP_AT} found. Stopping.`);
          await updateCart(ID_TO_STOP_AT);
          await revalidate(listCarts.key);
          return data;
        }

        console.log(`Current CartID: ${data.CurrentCartID}. Continuing...`);
        currentId = data.CurrentCartID;
      } catch (error) {
        console.error("Error occurred:", error);
        throw error;
      }
    }
  };
  // !DELETE
  const addDummyItemToCurrentCart = async () => {
    const LENGTH = {
      Type: "length",
      Quantity: 1,
      Plant: "",
      Tag: "LENGTH",
      SubItems: [{ SKU: "2548083", Moulding: "2548083", Length: 10 }],
      Cutting: "sixAndBalance",
    };
    const CHOP = {
      Type: "chop",
      Quantity: 1,
      Plant: "",
      Tag: "CHOP - NO WEDGE",
      SubItems: [
        {
          SKU: "2548045",
          Moulding: "2548045",
          Length: 20,
          Width: 10,
          Layer: "I",
        },
      ],
      Allowance: "1/8",
    };
    const JOIN = {
      Type: "join",
      Quantity: 1,
      Plant: "",
      Allowance: "1/8",
      Tag: "JOIN - OUTSIDE MEASUREMENTS",
      SubItems: [
        {
          SKU: "2548009",
          Moulding: "2548009",
          Length: 20,
          Width: 10,
          Layer: "O",
        },
      ],
    };
    const JOINWITHFRACTION = {
      Type: "join",
      Quantity: 1,
      Plant: "",
      SubItems: [
        {
          SKU: "2548083",
          Moulding: "2548083",
          Length: 12,
          Width: 10,
          Layer: "I",
          LengthFraction: "1/4",
          WidthFraction: "1/2",
        },
      ],
      Allowance: "1/8",
    };
    const BOX = {
      Type: "box",
      Quantity: 1,
      Plant: "",
      FreightAccepted: true,
      Oversized: true,
      Tag: "BOX",
      SubItems: [{ SKU: "10776", Moulding: "10776", Length: 1 }],
    };
    const RAIL = {
      Type: "rail",
      Quantity: 1,
      Plant: "",
      Tag: "RAIL",
      SubItems: [{ SKU: "10732", Moulding: "10732", Length: 12, Layer: "I" }],
      Allowance: "exact",
    };
    const PHOTOFRAME = {
      Type: "photoFrame",
      Quantity: 2,
      Plant: "",
      Tag: "PHOTOFRAME",
      SubItems: [{ SKU: "2020083-57", Moulding: "2020083" }],
      FrameSize: { Width: 5, Length: 7 },
    };
    const GALLERYFRAME = {
      Type: "galleryFrame",
      Quantity: 6,
      Plant: "",
      Tag: "GALLERYFRAME",
      SubItems: [{ SKU: "5041077GF-1824M1", Moulding: "5041077" }],
      FrameSize: { Width: 18, Length: 24 },
    };
    const CORNERSAMPLE = {
      Type: "cornerSample",
      Quantity: 1,
      Plant: "",
      Tag: "CORNERSAMPLE",
      SubItems: [{ SKU: "10741-COR", Moulding: "10741" }],
    };
    const STACKJOIN2 = {
      Type: "stack",
      Quantity: 1,
      Plant: "",
      Tag: "2-STACK-JOIN",
      SubItems: [
        {
          SKU: "2548083",
          Moulding: "2548083",
          Layer: "I",
          Length: 20,
          Width: 10,
        },
        {
          SKU: "2548087",
          Moulding: "2548087",
          Length: 22,
          Width: 12,
          Layer: "O",
        },
      ],
      Operation: "join",
      Allowance: "1/8",
      FrameSize: {
        Sizing: "inside",
        Width: 10,
        Length: 20,
        WidthFraction: "",
        LengthFraction: "",
        Layers: { O: "2548087", M2: "", I: "2548083" },
      },
    };
    const STACKCHOP3 = {
      Type: "stack",
      Quantity: 1,
      Plant: "",
      Tag: "3-STACK-CHOP-WEDGE",
      SubItems: [
        {
          SKU: "2548083",
          Moulding: "2548083",
          Layer: "I",
          Length: 20,
          Width: 10,
          Wedge: "single",
        },
        {
          SKU: "2548009",
          Moulding: "2548009",
          Layer: "2",
          Length: 22,
          Width: 12,
          Wedge: "single",
        },
        {
          SKU: "2548087",
          Moulding: "2548087",
          Length: 24,
          Width: 14,
          Layer: "O",
          Wedge: "single",
        },
      ],
      Operation: "chop",
      Allowance: "1/8",
      FrameSize: {
        Sizing: "inside",
        Width: 10,
        Length: 20,
        WidthFraction: "",
        LengthFraction: "",
        Layers: { O: "2548087", M2: "2548009", I: "2548083" },
      },
    };

    await addToCart(JOIN);
  };

  const CartItemsSkeleton = () => {
    return (
      <div class="grow flex flex-col text-center items-center justify-center  bg-gray-200 my-4 rounded-lg animate-pulse">
        <BaseLoader width={8} height={8} />
      </div>
    );
  };

  const CartOptionButtons = () => {
    return (
      <div class="py-2 space-x-4">
        <button
          onClick={() => setShowCartOptions("switch")}
          class="pb-2 text-gray-500 hover:text-gray-700 active:translate-y-0.5"
          classList={{
            "border-b-2 border-roma-blue text-roma-blue pointer-events-none":
              showCartOptions() === "switch",
          }}
        >
          Switch Cart
        </button>
        <button
          onClick={() => setShowCartOptions("add")}
          class="pb-2 text-gray-500 hover:text-gray-700 active:translate-y-0.5"
          classList={{
            "border-b-2 border-roma-blue text-roma-blue pointer-events-none":
              showCartOptions() === "add",
          }}
        >
          Add Cart
        </button>
      </div>
    );
  };

  const CreateNewCartForm = () => {
    return (
      <form
        class="flex  space-x-2"
        onSubmit={async (e: SubmitEvent) => {
          e.preventDefault();
          const form = e.target as HTMLFormElement;
          const formData = new FormData(form);
          const cartName = formData.get("cart-name");
          console.log("using createCartAction, name:", cartName);
          await createCart(cartName as string);
          setShowCartOptions(undefined);
        }}
      >
        <TextFieldInput
          disabled={aCartActionIsPending()}
          type="text"
          name="cart-name"
          rootClass="grow"
        />
        <button
          disabled={aCartActionIsPending()}
          class="bg-roma-blue text-white rounded-md px-2 disabled:bg-roma-medium-grey disabled:cursor-not-allowed flex items-center justify-center"
        >
          {creatingCart.pending ? (
            <BaseLoader width={5} height={5} class="!mr-0" />
          ) : (
            <span>Create Cart</span>
          )}
        </button>
      </form>
    );
  };

  return (
    <>
      {/* <div class="text-sm text-orange-500">
        <p>updatingCart? {updatingCart.pending ? "TRUE" : "FALSE"}</p>
        <p>creatingCart? {creatingCart.pending ? "TRUE" : "FALSE"}</p>
        <p>addingToCart? {addingToCart.pending ? "TRUE" : "FALSE"}</p>
        <p>deletingCart? {deletingCart.pending ? "TRUE" : "FALSE"}</p>
        <p>clearingCart? {clearingCart.pending ? "TRUE" : "FALSE"}</p>
      </div> */}
      <div class="py-2 border-b  text-sm">
        <div class="flex justify-between space-x-2 items-start">
          <Show
            when={showCartOptions()}
            fallback={
              <div class="truncate">
                <p class="truncate font-medium text-lg">{cart()?.Name}</p>
                <p class="font-light">
                  {cart()?.LineCount} Item{cart()?.LineCount! > 1 ? "s" : ""}
                </p>
              </div>
            }
          >
            <CartOptionButtons />
          </Show>

          <button
            onClick={() =>
              showCartOptions()
                ? setShowCartOptions(undefined)
                : setShowCartOptions("switch")
            }
            class="text-roma-blue shrink-0 mt-1.5 flex items-center active:translate-y-0.5"
          >
            <span> {showCartOptions() ? "Hide" : "Switch/Add Cart"}</span>
          </button>
        </div>

        <Show when={showCartOptions()} keyed>
          {(option) => (
            <Motion.div
              initial={{ opacity: 0, x: option === "add" ? "50%" : "-50%" }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: option === "add" ? "50%" : "-50%" }}
              class="py-2 space-y-1  mx-1"
            >
              <Show when={cartOptions() && option === "switch"}>
                <div>
                  <SelectBox
                    valueClass="truncate"
                    options={cartOptions()!}
                    onChange={(option) => {
                      updateCart(option.value as string);
                    }}
                    value={{ value: session()?.cart_id }}
                    loading={aCartActionIsPending()}
                    disabled={aCartActionIsPending()}
                  />
                </div>
              </Show>
              <Show when={option === "add"}>
                <CreateNewCartForm />
              </Show>
            </Motion.div>
          )}
        </Show>
      </div>

      <Suspense fallback={<CartItemsSkeleton />}>
        {/* Some blocker for preventing interactions with cart items while actions are pending ?  */}
        {/* <div
            class={`${
              aCartActionIsPending()
                ? "visible"
                : "invisible"
            } absolute inset-x-0 inset-y-0 backdrop-blur-[1px] pointer-events-none !border-t-0`}
          /> */}
        <div class="grow divide-y overflow-y-auto  py-4">
          <Show
            when={cart()?.Lines?.length > 0}
            fallback={
              <div class="flex flex-col items-center justify-center h-full">
                <p>This cart is empty</p>
                <p>TODO - add some links etc..</p>
              </div>
            }
          >
            <For each={cart()?.Lines}>
              {(line, idx) => (
                <>
                  <pre class="text-[10px] select-all">
                    {JSON.stringify(line, null, 2)}
                  </pre>
                </>
              )}
            </For>
          </Show>
        </div>
      </Suspense>

      <div class="border-t border-roma-medium-grey h-auto  text-sm shrink-0 mt-auto py-2 space-y-2 flex flex-col items-center">
        <div class="text-xs border border-orange-500 space-x-4">
          <button
            class="hover:text-red-500"
            onClick={() => deleteAllCartsButTheTwoIWant(cart()?.ID)}
          >
            DELETE-ALL-CARTS
          </button>
          <button
            class="hover:text-red-500"
            onClick={() => addDummyItemToCurrentCart()}
          >
            ADD-ITEM-TO-CURRCART
          </button>
        </div>

        <div class="flex justify-between text-lg font-bold w-full px-4 ">
          <p>Total</p>
          <p>${numberWithCommas(cart()?.Amount.toFixed(2))}</p>
        </div>

        <div class="w-full flex justify-between space-x-2 pb-2">
          <Button style="outline" disableHover>
            <span class="">Continue Ordering</span>
          </Button>
          <Button
            rootClass="grow"
            class="w-full"
            disableHover
            disabled={aCartActionIsPending()}
          >
            <span class="px-2">View Cart & Checkout</span>
          </Button>
        </div>

        <div class="border-t w-full py-1 flex space-x-4 justify-between text-red-600 text-xs">
          <button onClick={handleEmpty}>Empty Cart</button>
          <button onClick={handleDelete}>Delete Cart</button>
          {Prompt}
        </div>
      </div>
    </>
  );
};
