import { Component, Show, createEffect } from "solid-js";
import { A, useNavigate, useSearchParams } from "@solidjs/router";
import Button from "../Button";
import { TextFieldInput } from "../inputs";
import { Icon } from "solid-heroicons";
import { checkCircle } from "solid-heroicons/outline";
import { eyeSlash } from "solid-heroicons/solid";
import { Loader } from "../utility";

import { createForm } from "@felte/solid";
import { validator } from "@felte/validator-zod";
import * as z from "zod";

import { useSessionContext } from "~/utils/contexts";

import { useAction, useSubmission } from "@solidjs/router";
import { loginAction } from "~/services/account";

// TODO:
//  add session/setSession

const SignIn: Component = () => {
  const { setSession, session } = useSessionContext();
  const login = useAction(loginAction);
  const submission = useSubmission(loginAction);

  const [, setParams] = useSearchParams<{ signIn?: string }>();
  const navigate = useNavigate();

  const nonEmpty = z
    .string()
    .trim()
    .min(1, { message: "Field cannot be empty" });

  const schema = z.object({
    username: nonEmpty,
    password: nonEmpty,
  });

  const { form, data, setData, errors, isDirty } = createForm<{
    username: string;
    password: string;
  }>({
    extend: validator({ schema }),
    onSubmit: async (values) => {
      const response = await login(values.username, values.password);

      if ("Token" in response && response.Token !== "") {
        await setSession({
          token: response.Token,
          name: response.FirstName,
          cart_id: response.CurrentCartID,
          last_login: new Date(),
        });

        // * permissions are handled in ProfileQuickLinks
        setTimeout(() => setParams({ signIn: undefined }), 1250);
      } else if ("Reset" in response) {
        navigate(
          `/forgot-password?code=${response.Code}&email=${response.Email}`
        );
      }
    },
    onError: (error, context) => {
      console.log("onError", error);
    },
  });
  form;

  // const [form, setForm] = createStore({
  //   username: "",
  //   password: "",
  //   send: false,
  //   transfer: false,
  //   customerNumber: "",
  //   email: "",
  //   firstName: "",
  //   lastName: "",
  //   telephone: "",
  //   exclusives: false,
  //   updates: false,
  //   error: null as string | null,
  // });
  // const [login] = createResource(
  //   () => form.send,
  //   async () => {
  //     if (form.transfer === true && form.customerNumber == "") {
  //       setForm(
  //         "error",
  //         "In order to continue setting up your account you need to supply a valid customer number. Please contact Roma support for additional information."
  //       );
  //       return;
  //     }
  //     setForm("error", null);
  //     const response = await fetch(
  //       `${import.meta.env.VITE_ROMA_API}/account/${
  //         form.transfer ? "transfer" : "login"
  //       }`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(
  //           form.transfer
  //             ? {
  //                 Username: form.username,
  //                 Password: form.password,
  //                 FirstName: form.firstName,
  //                 LastName: form.lastName,
  //                 Telephone: form.telephone,
  //                 Email: form.email,
  //                 Exclusive: form.exclusives,
  //                 Updates: form.updates,
  //               }
  //             : {
  //                 Username: form.username,
  //                 Password: form.password,
  //               }
  //         ),
  //       }
  //     );
  //     const data = await response.json();
  //     if (response.status !== 200) {
  //       setForm("send", false);
  //       switch (data.Code) {
  //         case "ALREADY_TRANSFERED":
  //           setForm(
  //             "error",
  //             "The username or password you supplied was incorrect."
  //           );
  //           break;
  //         case "INVALID_LOGIN":
  //           setForm(
  //             "error",
  //             "The username or password you supplied was incorrect."
  //           );
  //           break;
  //         case "VALIDATION_ERROR":
  //           setForm(
  //             "error",
  //             "Please enter a correct email address, phone number and other required fields before continuing."
  //           );
  //           break;
  //         case "ACCOUNT_TRANSFERRED":
  //           setForm(
  //             "error",
  //             "This account has already been transferred. Please try logging in with the email you set during the account transfer process."
  //           );
  //           break;
  //         case "EMAIL_REGISTERED":
  //           setForm(
  //             "error",
  //             "The supplied email address is already in use, please try another one."
  //           );
  //           break;
  //         default:
  //           setForm("error", data.Message);
  //       }
  //       return false;
  //     }
  //     if (data.Transfer) {
  //       setForm({
  //         customerNumber: data.CustomerNumber,
  //         firstName: data.FirstName,
  //         lastName: data.LastName,
  //         telephone: data.Telephone,
  //         email: data.Email,
  //         transfer: true,
  //         send: false,
  //       });
  //       return;
  //     } else if (data.Reset) {
  //       // Password reset request has been issued
  //       navigate(`/forgot-password?code=${data.Code}&email=${data.Email}`);
  //       return;
  //     }
  //     //! setSession("name", data.FirstName);
  //     //! setSession("token", data.Token);
  //     setTimeout(() => setParams({ signIn: undefined }), 1250);
  //     const cartsRequest = await fetch(
  //       `${import.meta.env.VITE_ROMA_API}/cart`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${data.Token}`,
  //         },
  //       }
  //     );
  //     const carts = await cartsRequest.json();
  //     if (carts.Total != 0) {
  //       //! setSession("cart_id", carts.Results[0].ID);
  //     } else {
  //       const newCartRequest = await fetch(
  //         `${import.meta.env.VITE_ROMA_API}/cart`,
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${data.Token}`,
  //           },
  //           body: JSON.stringify({}),
  //         }
  //       );
  //       const newCart = await newCartRequest.json();
  //       //! setSession("cart_id", newCart.ID);
  //     }
  //     return true;
  //   },
  //   {
  //     initialValue: false,
  //     ssrLoadFrom: "initial",
  //   }
  // );

  return (
    <Show
      when={!session()}
      fallback={
        <div class="flex p-10 justify-center items-center gap-3 text-xl">
          <Icon path={checkCircle} class="w-12" />
          Welcome back {session()?.name}!
        </div>
      }
    >
      <form use:form class="flex flex-col gap-6">
        <div>
          <h3 class="text-xl font-bold text-center mb-2">Sign In</h3>
          <p class="text-roma-dark-grey text-center">
            To access ordering and pricing, please sign into your account.
          </p>
        </div>
        <Show when={submission.error}>
            <div class="border-2 border-red-500 text-red-500 p-3 rounded-md">
              {submission.error.message}
            </div>
          </Show>
        <TextFieldInput
          name="username"
          class="text-base"
          label="Username/Email"
          autocomplete="email"
          type="text"
          required={true}
          disabled={submission.pending}
          error={errors().username}
          validationState={errors().username ? "invalid" : "valid"}
        />
        <TextFieldInput
          name="password"
          class="text-base"
          label="Password"
          type="password"
          required={true}
          icon={eyeSlash}
          disabled={submission.pending}
          autocomplete="password"
          error={errors().password}
          validationState={errors().password ? "invalid" : "valid"}
        />
        <div class="flex justify-end">
          <A href="/forgot-password" class="text-sm text-roma-blue font-bold">
            Forgot Password?
          </A>
        </div>
        <p class="text-sm text-roma-dark-grey text-center">
          By logging in, you agree to Roma Moulding's{" "}
          <A href="/privacy-policy" class="underline">
            Privacy Policy
          </A>
          .
        </p>
        <Button
          tabIndex="3"
          class="flex gap-2 w-full text-base"
          disabled={submission.pending}
          type="submit"
        >
          <div class="w-full  flex items-center justify-center relative">
            <span>Sign In</span>
            <div class="absolute right-0">
              <Loader
                show={submission.pending}
                class="!mr-0"
                height="5"
                width="5"
              />
            </div>
          </div>
        </Button>
        <p class="text-sm font-medium text-center">
          Not a partner?{" "}
          <A href="/support/partner" class="underline">
            Join us
          </A>
        </p>
      </form>
    </Show>
  );
};

export default SignIn;
