import { Component } from "solid-js";

const ContactInfo:Component = () => {
  return (
    <ul class="inline-flex flex-col w-full child:px-6 child:py-2 transition-colors duration-100">
      <li class="hover:text-roma-blue transition-colors duration-100">
        <a href="tel:18002632322" rel="nofollow">1 800 263 2322</a>
      </li>

      <li class="hover:text-roma-blue transition-colors duration-100">
        <button
          aria-label="Launch customer support chat"
          onClick={() => {
            // @ts-expect-error
            window.LiveChatWidget.call("maximize");
          }}
        >
          Chat Live!
        </button>
      </li>

      <li class="hover:text-roma-blue transition-colors duration-100">
        <a href="mailto:info@romamoulding.com">Email Us</a>
      </li>
    </ul>
  );
};

export default ContactInfo;
