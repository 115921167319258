import {
  For,
  type VoidComponent,
  createSignal,
  Show,
  createEffect,
} from "solid-js";
import { useContext } from "@corvu/drawer";
import { Icon } from "solid-heroicons";
import {
  xMark,
  heart,
  shoppingCart,
  shoppingBag,
  plus,
} from "solid-heroicons/outline";
import { heart as heartSolid } from "solid-heroicons/solid";
import Drawer from "@corvu/drawer";
import { CartPanel } from "./CartPanel";
import { FavouritesPanel } from "./FavouritesPanel";
import { useListCarts } from "~/services/cart";
import { useSessionContext } from "~/utils/contexts";
import { createAsync, useSubmission } from "@solidjs/router";
import { retrieveCart, addToCartAction } from "~/services/cart";
import {
  addFavouriteAction,
  deleteFavouriteAction,
  useFavourites,
  getFavourites,
} from "~/services/favourites";
import { Motion, Presence } from "solid-motionone";
import { BaseLoader } from "~/components/utility";

export const SidePanel: VoidComponent = () => {
  const { isPartner, session } = useSessionContext();
  const [mode, setMode] = createSignal<"fav" | "cart">("fav");
  const [width, setWidth] = createSignal(400);

  // !TESTING
  const addingToCart = useSubmission(addToCartAction);

  const favs = createAsync(()=>getFavourites())

  const addingFav = useSubmission(addFavouriteAction);
  const deletingFav = useSubmission(deleteFavouriteAction);
  const favActionIsPending = () => addingFav.pending || deletingFav.pending;

  const cartList = useListCarts();
  const cart = createAsync(async () => {
    if (!isPartner()) return;

    return retrieveCart(session()?.cart_id);
  });


  return (
    <Drawer side="right">
      {(props) => (
        <>
          <Show when={isPartner()}>
            <div class="flex ml-4 space-x-4">
              <Drawer.Trigger
                as="button"
                aria-label="Favourites"
                onClick={() => {
                  setMode("fav");
                }}
                class="relative hover:text-roma-blue transition-all duration-100  active:translate-y-0.5 whitespace-nowrap"
              >
                <Icon
                  stroke-width="1.75"
                  path={favActionIsPending() ? heartSolid : heart}
                  class={`w-5 h-5 ${
                    favActionIsPending() ? "text-roma-blue animate-pulse" : ""
                  }`}
                />
              </Drawer.Trigger>
              <Drawer.Trigger
                as="button"
                aria-label="Shopping Cart"
                onClick={() => {
                  setMode("cart");
                }}
                class="relative hover:text-roma-blue transition-all duration-100  active:translate-y-0.5 whitespace-nowrap"
              >
                <Show
                  when={!addingToCart.pending}
                  fallback={<BaseLoader width={5} class="!mr-0" />}
                >
                  <Icon
                    stroke-width="1.75"
                    path={shoppingCart}
                    class="w-5 h-5"
                  />
                </Show>
                <Show when={cart()?.LineCount} keyed>
                  {(lineCount) => (
                    <div
                      aria-hidden
                      class="absolute top-3 -right-2 text-[10px] flex items-center justify-center bg-roma-blue text-white rounded-full  w-4 h-4"
                      classList={{
                        "animate-pulse animate-bounce": addingToCart.pending,
                      }}
                    >
                      <span>{lineCount > 9 ? "9+" : lineCount}</span>
                    </div>
                  )}
                </Show>
              </Drawer.Trigger>
            </div>
          </Show>

          <Drawer.Portal>
            <Drawer.Overlay
              class="fixed inset-0 z-50 corvu-transitioning:transition-colors corvu-transitioning:duration-500 corvu-transitioning:ease-[cubic-bezier(0.32,0.72,0,1)]"
              style={{
                "background-color": `rgb(0 0 0 / ${
                  0.3 * props.openPercentage
                })`,
                "backdrop-filter": `blur(${2 * props.openPercentage}px)`,
              }}
            />

            <Drawer.Content class="group flex fixed w-[28rem] inset-y-0 right-0 z-50   rounded-l-lg border-l-2 border-gray-200 bg-white pl-1 pr-2 after:absolute after:inset-x-0 after:top-[calc(100%-1px)] after:h-1/2 after:bg-inherit corvu-transitioning:transition-[transform,width] corvu-transitioning:duration-500 corvu-transitioning:ease-[cubic-bezier(0.32,0.72,0,1)] md:select-none max-h-screen">
              <div class="h-10 w-1 self-center rounded-full bg-gray-200 mr-2 " />
              <div
                class="grow flex flex-col corvu-group-transitioning:transition-[width] corvu-group-transitioning:duration-500 corvu-group-transitioning:ease-[cubic-bezier(0.32,0.72,0,1)] pt-5 overflow-hidden max-h-fit"
                // style={{
                //   height: `${
                //     props.openPercentage < 0.7 ? 70 : props.openPercentage * 100
                //   }%`,
                // }}
              >
                <div class="flex justify-between">
                  <Drawer.Label class="py-2  text-lg font-bold">
                    {mode() === "cart" ? "Shopping Cart" : "Favourites"}
                  </Drawer.Label>
                  <Drawer.Close class="text-roma-medium-grey hover:text-black">
                    <Icon path={xMark} class="w-5 h-5" />
                  </Drawer.Close>
                </div>
                <Show when={isPartner()}>
                  <div class="border-b text-sm font-medium space-x-4">
                    <button
                      class="py-2 text-gray-500 hover:text-gray-700 active:translate-y-0.5"
                      classList={{
                        "text-roma-blue  border-b-2 border-roma-blue pointer-events-none":
                          mode() === "fav",
                      }}
                      onClick={() => setMode("fav")}
                    >
                      Favourites
                    </button>
                    <button
                      class="py-2 text-gray-500 hover:text-gray-700 active:translate-y-0.5"
                      classList={{
                        "text-roma-blue border-b-2 border-roma-blue pointer-events-none":
                          mode() === "cart",
                      }}
                      onClick={() => setMode("cart")}
                    >
                      Cart
                    </button>
                  </div>
                </Show>

                {/* <div>TEST:
                  <button class="border border-pink-500 px-2 mx-2" onClick={()=>setWidth(400)}>w-400</button>
                  <button class="border border-pink-500 px-2 mx-2" onClick={()=>setWidth(600)}>w-600</button>
                  <button class="border border-pink-500 px-2 mx-2" onClick={()=>setWidth(800)}>w-800</button>
                </div>
                <div class="h-3 bg-pink-500" style={{ width: width() + "px"}} /> */}
                <Presence exitBeforeEnter initial={false}>
                  <Show when={mode() === "cart"}>
                    <Motion.div
                      class="h-full flex flex-col overflow-hidden"
                      initial={{ x: "100%", opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ opacity: 0, transition: { duration: 0.1 } }}
                    >
                      <CartPanel carts={cartList} currentCart={cart} />
                    </Motion.div>
                  </Show>

                  <Show when={mode() === "fav"}>
                    <Motion.div
                      class="h-full flex flex-col overflow-hidden"
                      initial={{ x: "-100%", opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ opacity: 0, transition: { duration: 0.1 } }}
                    >
                      <FavouritesPanel />
                    </Motion.div>
                  </Show>
                </Presence>
              </div>
            </Drawer.Content>
            {/* !END STICKY FOOTER */}
          </Drawer.Portal>
        </>
      )}
    </Drawer>
  );
};
