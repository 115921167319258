import type { Component } from "solid-js";

const Hamburger: Component<{
  class?: string;
  opened: () => boolean;
  setOpened: (val: boolean) => void;
}> = (props) => {
  return (
    <button
      aria-label={props.opened() ? "Close menu": "Open menu"}
      class={`group h-10 w-8 ml-5 x-50 relative focus:outline-none z-50 ${
        props.class || ""
      }`}
      onClick={() => props.setOpened(!props.opened())}
    >
      <span class="sr-only">Open main menu</span>
      <div class="flex w-5 mt-2">
        <span
          class="block absolute shadow h-0.5 w-6 bg-current transform transition-transform duration-300 ease-in-out group-hover:bg-accent"
          classList={{
            "rotate-45": props.opened(),
            "-translate-y-1.5": !props.opened(),
          }}
        />
        <span
          class="block absolute shadow h-0.5 w-6 bg-current transform transition-opacity duration-300 ease-in-out group-hover:bg-accent"
          classList={{ "opacity-0": props.opened() }}
        />
        <span
          class="block absolute shadow h-0.5 w-6 bg-current transform transition-transform duration-300 ease-in-out group-hover:bg-accent"
          classList={{
            "-rotate-45": props.opened(),
            "translate-y-1.5": !props.opened(),
          }}
        />
      </div>
    </button>
  );
};

export default Hamburger;
