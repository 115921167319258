import { Show } from "solid-js";
import { useNavigate, createAsync, useSubmission } from "@solidjs/router";
import { logoutAction } from "~/services/session";
import { useAccountStatus } from "~/services/account";
import { useSessionContext } from "~/utils/contexts";
import { Icon } from "solid-heroicons";
import { arrowRightOnRectangle } from "solid-heroicons/outline";
import { user } from "solid-heroicons/outline";
import { BaseLoader, Menu, type MenuGroup } from "../utility";
import {
  accountNav,
  shoppingNav,
  adminNav,
  filterByPermissions,
} from "~/utils/account";

export const ProfileQuickLinks = () => {
  const { clearSession, setSession, session } = useSessionContext();
  const navigate = useNavigate();
  const loggingOut = useSubmission(logoutAction);

  const navItems = [
    { label: "Admin", list: adminNav },
    { label: "Account", list: accountNav },
    { label: "Shopping", list: shoppingNav },
    {
      list: [
        {
          label: "Log Out",
          icon: arrowRightOnRectangle,
          permission: null,
          onSelect() {
            clearSession();
            // navigate("/");
          },
        },
      ],
    },
  ];

  createAsync(
    async () => {
      if (!!session()?.token && !session()?.permissions) {
        const accountStatus = await useAccountStatus();
        if (accountStatus && "Permissions" in accountStatus) {
          await setSession({ permissions: accountStatus.Permissions });
        }
      }
    },
    { deferStream: true }
  );

  const filteredMenuOptions = () =>
    navItems.map((item) => ({
      label: item.label,
      list: filterByPermissions(item.list, session()?.permissions ?? []),
    }));

  return (
    <Menu aria-label="Account Menu" list={filteredMenuOptions() as MenuGroup[]}>
      <div class="flex items-center hover:text-roma-blue">
        <Show
          when={loggingOut.pending}
          fallback={
            <Icon path={user} class="w-5 h-5 mr-1" stroke-width="1.75" />
          }
        >
          <BaseLoader width={5} height={5} class="!mr-1" />
        </Show>
        <span>Account Menu</span>
      </div>
    </Menu>
  );
};
