import { Accessor, Component, Show, useContext, Switch, Match } from "solid-js";
// import { A } from "solid-start";
import { A } from "@solidjs/router";
import { SiteContext } from "~/utils/contexts";
// import { Motion } from "@motionone/solid";
import { Motion } from "solid-motionone";
import { Icon } from "solid-heroicons";
import { heart } from "solid-heroicons/outline";
import { heart as heartSolid } from "solid-heroicons/solid";
// import { ProductImage } from "~/components/image";
import { ProductImage } from "~/components/product";
import fractionString from "~/utils/fraction";
import {
  MOULDING,
  PHOTOFRAME,
  GALLERYFRAME,
  MIRROR,
  CONTRACT,
} from "~/utils/products";
import { type Product } from "~/utils/types";
import { EventType } from "@solid-primitives/analytics";
import { useSessionContext, useSiteContext } from "~/utils/contexts";

type SearchProductCardProps = {
  product: Product;
  image1: string;
  type: string;
  class?: string;
  index: Accessor<number>;
};

export const pathMapping = {
  [PHOTOFRAME]: "/photo-frame",
  [GALLERYFRAME]: "/gallery-frame",
  [MIRROR]: "/mirror",
  [MOULDING]: "",
};

export const SearchProductCard: Component<SearchProductCardProps> = (props) => {
  // const { session, toggleFavourite, track } = useContext(SiteContext);
  const { session, toggleFav, isFavourited } = useSessionContext();
  const { track } = useSiteContext();
  const favourite = () => isFavourited(props.product.SKU, props.type);
  
  return (
    <Motion.div
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        duration: 0.1,
        delay: props.index() < 10 ? props.index() * 0.06 : 0,
        easing: "ease-in-out",
      }}
      class="flex flex-col gap-y-2 sm:gap-y-4 col-span-2 group"
      classList={{ [`${props.class}`]: !!props.class }}
    >
      <A
        href={`/product/${props.product.SKU}${
          props.type == CONTRACT ? `?type=${CONTRACT}` : ""
        }${pathMapping[props.type] || ""}`}
        class="border border-transparent hover:border-gray-200 transition"
        onClick={() => {
          track(EventType.Event, {
            category: "search",
            action: "product_selected",
            value: `${props.product.SKU} - ${props.type}`,
          });
        }}
      >
        <div class="w-full group aspect-square bg-roma-grey flex items-center justify-center relative">
          <div
            class="w-full aspect-square flex items-center justify-center "
            classList={{
              "p-0": props.type == GALLERYFRAME,
              "p-4 md:p-6": props.type !== GALLERYFRAME,
            }}
          >
            <ProductImage
              src={props.image1}
              layout="fullWidth"
              aspectRatio={1}
              quality={85}
              breakpoints={[300, 440]}
              alt={`SKU #${props.product.SKU}`}
              loading="eager"
            />
          </div>
          <Switch>
            <Match when={props.product.OnSale}>
              <div class="absolute top-2 left-2 bg-red-500 rounded-full px-2 py-0.5 text-xs text-white">
                On Sale
              </div>
            </Match>
            <Match when={props.product.ComingSoon}>
              <div class="absolute top-2 left-2 bg-roma-blue rounded-full px-2 py-0.5 text-xs text-white">
                Coming {props.product.ArrivalDate ?? "Soon"}
              </div>
            </Match>
            <Match when={props.product.IsNew}>
              <div class="absolute top-2 left-2 bg-roma-blue rounded-full px-2 py-0.5 text-xs text-white">
                New
              </div>
            </Match>
          </Switch>
        </div>
      </A>
      <div>
        <div class="flex justify-between">
          <p class="font-bold text-sm sm:text-base tracking-wide">
            {props.product.SKU}
          </p>
          <div class="flex gap-1 pr-1">
            <button
              aria-label={
                favourite()
                  ? `Remove product ${props.product.SKU} from favourites`
                  : `Add product ${props.product.SKU} to favourites`
              }
              onClick={(evt: Event) => {
                evt.preventDefault();
                toggleFav(props.product.SKU, props.type);
                // track(EventType.Event, {
                //   category: "favourite",
                //   action: "selected",
                //   label: props.product.SKU,
                //   value: props.type,
                // });
              }}
            >
              <Show
                fallback={
                  <Icon
                    path={heart}
                    class="w-5 sm:w-6 cursor-pointer text-roma-blue"
                  />
                }
                when={favourite()}
              >
                <Icon
                  path={heartSolid}
                  class="w-5 sm:w-6 cursor-pointer text-roma-blue"
                />
              </Show>
            </button>
          </div>
        </div>
        <p class="text-roma-medium-grey text-sm sm:text-base font-normal line-clamp-1">
          {props.product.Collection}, {props.product.ColourDescription}
        </p>
        <div class="max-xs:hidden flex gap-1 text-roma-medium-grey fraction font-medium text-xs sm:text-sm flex-wrap ">
          <Show when={props.product.Width}>
            <span class="whitespace-nowrap">
              W{fractionString(props.product.Width)}
            </span>
          </Show>
          <Show when={props.product.Height}>
            <span class="whitespace-nowrap">
              H{fractionString(props.product.Height)}
            </span>
          </Show>
          <Show when={props.product.Rabbet && props.product.Rabbet > 0}>
            <span class="whitespace-nowrap">
              R{fractionString(props.product.Rabbet)}
            </span>
          </Show>
          <Show when={props.product.Depth}>
            <span class="whitespace-nowrap">
              D{fractionString(props.product.Depth)}
            </span>
          </Show>
          <Show when={props.product.Face}>
            <span class="whitespace-nowrap">
              F{fractionString(props.product.Face)}
            </span>
          </Show>
        </div>
      </div>
    </Motion.div>
  );
};
